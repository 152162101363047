import { useQuery } from "@tanstack/react-query"
import { asReference } from "fhir"

import { useClient } from "api"
import { PractitionerApi } from "commons"

const usePractitioner = (practitionerId: string | undefined, enabled = true) => {
  const { read } = useClient()
  const queryKey = ["practitioner", practitionerId]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      if (!practitionerId) {
        throw new Error("Internal Error", { cause: { name: "500", message: "No practitioner id is provided" } })
      }

      const practitioner = await read<PractitionerApi>(`practitioners/${practitionerId}`)

      if (!practitioner) {
        throw new Error("Not found", { cause: { name: "404", message: "Not practitioner found" } })
      }

      return practitioner
    },
    enabled: !!enabled && !!practitionerId,
  })

  return {
    practitioner: data as PractitionerApi,
    practitionerRef: data?.practitioner ? asReference(data.practitioner) : undefined,
    isLoading,
  }
}

export { usePractitioner }
