import { Message } from "primereact/message"
import { FC } from "react"

import { CheckBoxField, FileUploaderField, ReferenceDropdownField } from "commons"
import { useOrganizationRefs } from "organizations"
import { usePractitionersOrganization } from "practitioners"

const PatientImportForm: FC<Props> = ({ isSubmitting, organizationId, overwritePatients }) => {
  const { organizationRefs, isLoading } = useOrganizationRefs()
  const { practitionerRefs, isLoading: isLoadingPracts } = usePractitionersOrganization({
    organizationId,
  })
  const organizationValue = organizationId && organizationRefs?.find((org) => org.id === organizationId)

  return (
    <>
      <ReferenceDropdownField
        field="organization"
        options={organizationRefs}
        filter
        filterBy="display"
        disabled={isSubmitting}
        isLoading={isLoading}
        label="Organization"
        value={organizationValue}
      />
      <ReferenceDropdownField
        field="practitioner"
        options={practitionerRefs}
        disabled={isSubmitting || !organizationId}
        isLoading={isLoadingPracts}
        label="General practitioner"
        filter={false}
      />
      <FileUploaderField field="file" accept="text/csv" mode="advanced" disabled={isSubmitting} label="File" />
      <div className="inline-flex flex-wrap w-full gap-3">
        <CheckBoxField field="invitePatients" label="Invite patients" />
        <CheckBoxField field="overwritePatients" label="Overwrite patients" />
        {!!overwritePatients && (
          <Message
            className="w-full"
            severity="warn"
            text="This action cannot be undone. Current patient's data will be replaced with uploaded records."
            pt={{ text: { className: "text-xs" } }}
          />
        )}
      </div>
    </>
  )
}

type Props = {
  isSubmitting?: boolean
  organizationId?: string
  overwritePatients?: boolean
}

export { PatientImportForm }
