import { useState, FC } from "react"
import { Reference } from "fhir"
import { faBuilding, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useOrganizationRefs } from "organizations"
import { getStringAddress } from "utils"

import { AutoCompleteReferenceField } from "./AutoCompleteReferenceField"

const OrganizationAutocomplete: FC<Props> = ({
  field,
  label,
  className,
  horizontal,
  inputClassName,
  labelClassName,
  organazationType,
  disabled,
  showClearIcon,
  validation,
}) => {
  const [filter, setFilter] = useState("")
  const { organizationRefs, organizations, isFetching, isLoading } = useOrganizationRefs(filter, organazationType)

  const orgTemplate = (option: Reference) => {
    const org = organizations?.[option.id!]
    return (
      org && (
        <div className="text-sm">
          <div className="font-bold">
            <FontAwesomeIcon icon={faBuilding} className="fa-fw mr-1" />
            <span title="Organization" className="text-base">
              {`${org.name} [${org.partOf?.display ?? "root"}]`}
            </span>
          </div>
          <div title="Address">
            <FontAwesomeIcon icon={faLocationDot} className="fa-fw mr-1" />
            {getStringAddress(org.address?.[0])}
          </div>
        </div>
      )
    )
  }

  return (
    <AutoCompleteReferenceField
      field={field}
      isLoading={isLoading || isFetching}
      optionList={organizationRefs}
      setFilter={setFilter}
      customItemTemplate={orgTemplate}
      validation={validation}
      className={className}
      disabled={disabled}
      horizontal={horizontal}
      inputClassName={inputClassName}
      label={label}
      labelClassName={labelClassName}
      showClearIcon={showClearIcon}
    />
  )
}

export { OrganizationAutocomplete }

type Props = {
  field: string
  label?: string
  className?: string
  isReadonly?: boolean
  horizontal?: boolean
  inputClassName?: string
  labelClassName?: string
  organazationType?: string
  disabled?: boolean
  showClearIcon?: boolean
  validation?(reference: Reference): void
}
