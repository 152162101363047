import { Form, Formik, FormikProps } from "formik"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { DropdownField, InputField, OrganizationAutocomplete, AutoCompletePractitionerRefs } from "commons"
import { genders, ORGANIZATION_TYPE } from "data"

import { FilterProps } from "../types"

const PatientFilters = ({ initialValues, hasActiveFilters, onSearch, onClearFilters }: Props) => {
  const renderForm = ({ isSubmitting, initialValues, values }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white gap-4">
      <InputField type="email" field="email" label="Email" className="w-full" inputClassName="text-sm mb-3" clearable />

      <DropdownField
        field="gender"
        label="Biological Sex"
        options={genders}
        className="w-full"
        inputClassName="text-sm"
        showClear
      />

      <OrganizationAutocomplete
        field="managingOrg"
        label="Organization"
        className="w-full"
        inputClassName="text-sm"
        labelClassName="!mt-0"
        organazationType={ORGANIZATION_TYPE.HEALTHCARE_PROVIDER}
      />

      <AutoCompletePractitionerRefs
        field="generalPractitioner"
        label="Practitioner"
        className="w-full"
        inputClassName="text-sm"
        labelClassName="!mt-0"
      />

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || !hasActiveFilters}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button
          type={JSON.stringify(initialValues) === JSON.stringify(values) ? "button" : "submit"}
          label="Search"
          loading={isSubmitting}
          className="p-button-sm button-primary"
        />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch} enableReinitialize>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { PatientFilters }
