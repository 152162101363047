import { useState, FC } from "react"
import { getFirstEmail, humanNameAsString, Reference } from "fhir"
import { faMailbox, faUserDoctor } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { usePractitionersRefs } from "practitioners"

import { AutoCompleteReferenceField } from "./AutoCompleteReferenceField"

const AutoCompletePractitionerRefs: FC<Props> = ({
  field,
  label,
  className,
  horizontal,
  inputClassName,
  labelClassName,
  disabled,
  showClearIcon,
  validation,
}) => {
  const [filter, setFilter] = useState("")
  const { practitionerRefs, practitioners, isLoading, isFetching } = usePractitionersRefs(filter)

  const practTemplate = (option: Reference) => {
    const pract = practitioners?.[option.id!]
    return (
      pract && (
        <div className="text-sm">
          <div className="font-bold">
            <FontAwesomeIcon icon={faUserDoctor} className="fa-fw mr-1" />
            <span title="Practitioner" className="text-base">
              {humanNameAsString(pract.name?.[0])}
            </span>
          </div>
          <div title="Email">
            <FontAwesomeIcon icon={faMailbox} className="fa-fw mr-1" />
            {getFirstEmail(pract.telecom)}
          </div>
        </div>
      )
    )
  }

  return (
    <AutoCompleteReferenceField
      field={field}
      isLoading={isLoading || isFetching}
      optionList={practitionerRefs}
      setFilter={setFilter}
      customItemTemplate={practTemplate}
      validation={validation}
      className={className}
      disabled={disabled}
      horizontal={horizontal}
      inputClassName={inputClassName}
      label={label}
      labelClassName={labelClassName}
      showClearIcon={showClearIcon}
    />
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  horizontal?: boolean
  inputClassName?: string
  labelClassName?: string
  disabled?: boolean
  showClearIcon?: boolean
  validation?(value: Reference): void
}

export { AutoCompletePractitionerRefs }
