import { getAPIResourcesAsIndex, Reference } from "fhir"
import { useMemo } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { enqueue } from "utils"

import { PatientApi, PatientInfo } from "../types"
import { patientQueryKey } from "../query-keys"

const COUNT_PER_PAGE = 20

const usePatients = (
  filter = "",
  email?: string,
  gender?: string,
  managingOrg?: Reference,
  generalPractitioner?: Reference,
) => {
  const { search } = useClient()
  const queryKey = patientQueryKey.list(filter, email, gender, managingOrg?.id, generalPractitioner?.id)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    PatientQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { termsearch: filter } : {}),
        ...(email ? { _email: email } : {}),
        ...(gender ? { _gender: gender } : {}),
        ...(generalPractitioner ? { "general-practitioner": generalPractitioner.id } : {}),
        ...(managingOrg ? { organization: managingOrg.id } : {}),
        _count: COUNT_PER_PAGE.toString(),
        _sort: ".name.0.family",
        _page: `${pageParam}`,
        _include: "organization, general-practitioner",
      })

      const bundle = await search("patients", filters, undefined, signal)

      const patients = bundle.entry as PatientApi[]
      const practitioners = getAPIResourcesAsIndex<Reference>(bundle, "Practitioner")
      const organizations = getAPIResourcesAsIndex<Reference>(bundle, "Organization")

      const patientsInfo = patients.map<PatientInfo>((pat) => {
        const patient = pat.patient
        const practioner = practitioners[pat.patient.generalPractitioner?.[0].id ?? ""]
        const organization = organizations[pat.patient.managingOrganization?.id ?? ""]
        const b2cUserId = pat.b2cUserId

        return { patient, practioner, organization, b2cUserId }
      })

      const next = (pageParam as number) * COUNT_PER_PAGE < (bundle?.total ?? 0) ? (pageParam as number) + 1 : undefined

      return { patientsInfo, practitioners, organizations, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, filter, email, gender } },
  })

  const { patientsGroups, count } = useMemo(() => {
    let count = 0

    const groupMap = data?.pages?.reduce((map, { patientsInfo }) => {
      count += patientsInfo.length

      patientsInfo.forEach((patient) => {
        const key = patient.patient?.name?.[0]?.family?.[0] ?? "other"
        const value = map.get(key)

        if (value) {
          enqueue(value, patient, "PatientInfo")
        } else {
          map.set(key, [patient])
        }
      })

      return map
    }, new Map<string, PatientInfo[]>())

    return {
      patientsGroups: groupMap
        ? Array.from(groupMap.entries()).map(([key, items]) => ({
            key,
            name: key,
            items,
          }))
        : [],
      count,
    }
  }, [data?.pages])

  return {
    patientsGroups,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadPatients: refetch,
  }
}

type PatientQueryData = { patientsInfo: PatientInfo[]; next: number | undefined; total: number }

export { usePatients }
