import type { Reference } from "fhir"
import { useMountEffect } from "primereact/hooks"
import { FC, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

import { FiltersContextProvider, SkeletonLoader } from "commons"
import { useOrganization } from "organizations"
import { usePractitioner } from "practitioners"

import { FilterProps } from "../types"
import { PatientList } from "./PatientList"

const PatientsView: FC = () => {
  const { organizationId } = useParams()

  const [searchParams] = useSearchParams()
  const [initialRender, setInitialRender] = useState(true)

  const managingOrgId = searchParams.get("managingOrg")
  const generalPractitionerId = searchParams.get("generalPractitioner")
  const initialSearchText = searchParams.get("searchText")

  const { organizationRef, isLoading: isLoadingOrg } = useOrganization(managingOrgId ?? undefined, initialRender)
  const { practitionerRef, isLoading: isLoadingPractitioner } = usePractitioner(
    generalPractitionerId ?? undefined,
    initialRender,
  )

  useMountEffect(() => setInitialRender(false))

  if (isLoadingOrg || isLoadingPractitioner)
    return (
      <>
        <SkeletonLoader loaderType="one-line" repeats={1} />
        <SkeletonLoader loaderType="form-two-cols" repeats={1} />
      </>
    )
  const initialFilters = parseParamsToFilters(searchParams, organizationRef, practitionerRef)

  return (
    <FiltersContextProvider
      initialFilters={initialFilters}
      initialSearchText={initialSearchText as string}
      filtersKeysValueMap={{
        managingOrg: (val) => (val as Reference | undefined)?.id,
        generalPractitioner: (val) => (val as Reference | undefined)?.id,
      }}
      setUrlFilters
    >
      <PatientList organizationId={organizationId as string} />
    </FiltersContextProvider>
  )
}

const DFAULT_FILTERS: FilterProps = {
  email: undefined,
  gender: undefined,
  managingOrg: undefined,
  generalPractitioner: undefined,
}

export { PatientsView }

const parseParamsToFilters = (
  searchParams: URLSearchParams,
  managingOrgRef?: Reference,
  generalPractitionerRef?: Reference,
): FilterProps => ({
  email: searchParams.get("email") ?? DFAULT_FILTERS.email,
  gender: searchParams.get("gender") ?? DFAULT_FILTERS.gender,
  managingOrg: searchParams.get("managingOrg")
    ? managingOrgRef ?? ({ id: searchParams.get("managingOrg"), resourceType: "Organization" } as Reference)
    : DFAULT_FILTERS.managingOrg,
  generalPractitioner: searchParams.get("generalPractitioner")
    ? generalPractitionerRef ??
      ({ id: searchParams.get("generalPractitioner"), resourceType: "Practitioner" } as Reference)
    : DFAULT_FILTERS.generalPractitioner,
})
