import { FC } from "react"
import { isValid, format, parseISO } from "date-fns"
import { useSearchParams } from "react-router-dom"
import { toZonedTime } from "date-fns-tz"

import { FiltersContextProvider } from "commons"
import { formatsByTypes } from "data"

import { FilterProps } from "../types"
import { SubsNotificationsListView } from "./SubsNotificationsListView"

const SubnotificationsView: FC = () => {
  const [searchParams] = useSearchParams()
  const initialSearchText = searchParams.get("searchText")
  const initialFilters = parseParamsToFilters(searchParams)

  return (
    <FiltersContextProvider
      initialFilters={initialFilters}
      initialSearchText={initialSearchText as string}
      setUrlFilters
      filtersKeysValueMap={{
        occurrence: (date) =>
          date && isValid(parseISO(new Date(date as string).toISOString()))
            ? format(date as string, formatsByTypes.ISO_8601_DATE)
            : undefined,
        status: (array) => (Array.isArray(array) ? (array.length ? array.join(",") : undefined) : array?.toString()),
      }}
    >
      <SubsNotificationsListView />
    </FiltersContextProvider>
  )
}

const DFAULT_FILTERS: FilterProps = {
  status: undefined,
  occurrence: undefined,
}

export { SubnotificationsView }

const parseParamsToFilters = (searchParams: URLSearchParams): FilterProps => ({
  status: searchParams.get("status") ? searchParams.get("status")?.split(",") : DFAULT_FILTERS.status,
  occurrence: searchParams.get("occurrence")
    ? toZonedTime(searchParams.get("occurrence") as string, "UTC")
    : DFAULT_FILTERS.occurrence,
})
