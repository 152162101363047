import { FC } from "react"
import { useSearchParams } from "react-router-dom"
import { Reference } from "fhir"

import { FiltersContextProvider, SkeletonLoader } from "commons"
import { useLoginContext } from "security"

import { FilterProps } from "../types"
import { OrganizationtList } from "./OrganizationList"
import { useOrganizationsPartOf } from "../hooks"

const OrganizationsView: FC = () => {
  const { logedInPractitioner } = useLoginContext()
  const {
    organizationsRoots,
    indexedOrgs,
    isLoading: isLoadingRoots,
  } = useOrganizationsPartOf(logedInPractitioner?.practitioner.id)

  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get("organizationId")
  const initialSearchText = searchParams.get("searchText")

  if (isLoadingRoots)
    return (
      <>
        <SkeletonLoader loaderType="one-line" repeats={1} />
        <SkeletonLoader loaderType="form-two-cols" repeats={1} />
      </>
    )

  const initialFilters = parseParamsToFilters(searchParams, indexedOrgs?.[organizationId as string])

  return (
    <FiltersContextProvider
      initialFilters={initialFilters}
      initialSearchText={initialSearchText as string}
      setUrlFilters
      filtersKeysValueMap={{
        active: (val) => (val === "true" ? "active" : val === "false" ? "deactivated" : undefined),
        orgRoot: (org) => (org as Reference | undefined)?.id,
      }}
      filtersKeysNameMap={{ orgRoot: "organizationId", active: "status" }}
    >
      <OrganizationtList organizationsRoots={organizationsRoots} />
    </FiltersContextProvider>
  )
}

const DFAULT_FILTERS: FilterProps = {
  orgRoot: undefined,
  active: undefined,
}

export { OrganizationsView }

const parseParamsToFilters = (searchParams: URLSearchParams, organizationRef?: Reference): FilterProps => ({
  active:
    searchParams.get("active") === "active"
      ? "true"
      : searchParams.get("status") === "deactivated"
        ? "false"
        : DFAULT_FILTERS.active,
  orgRoot: searchParams.get("organizationId")
    ? organizationRef ?? ({ id: searchParams.get("organizationId"), resourceType: "Organization" } as Reference)
    : DFAULT_FILTERS.orgRoot,
})
