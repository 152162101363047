import { classNames } from "primereact/utils"
import { FC, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { FormContainer, Slideover } from "commons"

import { useUploadPatients } from "../hooks"
import { ImportProgressData, UploadData } from "../types"
import { handleUpdateProgress } from "../utils"
import { ImportProgress } from "./ImportProgress"
import { PatientImportForm } from "./PatientImportForm"

const PatientImport: FC<Props> = ({ visible, onHide, isSmallViewport }) => {
  const { organizationId } = useParams()
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [progress, setProgress] = useState<ImportProgressData>()
  const handleHide = () => {
    setUploadedFile(undefined)
    setProgress(undefined)
    onHide()
  }

  const { uploadPatients, isUploading } = useUploadPatients({
    onSucces: (vars) => {
      setUploadedFile(vars.file)
    },
    onProgressUpdated: (update) => {
      setProgress((currentProgress) => handleUpdateProgress(currentProgress, update))
    },
    onError: handleHide,
  })

  const onSubmit = (data: UploadData) => {
    uploadPatients(data)
  }

  const isImportingSuccessfully = useMemo(() => !!uploadedFile && !!progress?.total, [uploadedFile, progress?.total])

  return (
    <Slideover
      showSlide={visible}
      position={isSmallViewport ? "bottom" : "right"}
      onHide={handleHide}
      className={isSmallViewport ? "h-[95%] rounded-t-xl" : "md:w-1/2 lg:w-1/3 min-w-96"}
      dismissable={false}
      showButtons={isImportingSuccessfully}
      cancelLabel="Close"
      showCloseIcon={isImportingSuccessfully}
      title="Import patients"
      onAccept={handleHide}
      childrenContainerClassName={classNames("flex flex-col h-full overflow-hidden", {
        "px-4 sm:px-6 space-y-6 pb-6 pt-1": !!progress?.total,
      })}
    >
      {uploadedFile && progress?.total ? (
        <ImportProgress file={uploadedFile} progress={progress} />
      ) : (
        <FormContainer
          title="Import patients"
          subTitle="Import patient records from a CSV file"
          initialValue={initialValues}
          disableSave={isUploading}
          onSubmit={onSubmit}
          onCancel={handleHide}
          showSave
          enableReinitialize
          showCloseIcon
          validationSchema={Yup.object({
            organization: Yup.object().nullable().required("Organization is required"),
            practitioner: Yup.object().nullable().required("General practitioner is required"),
            file: Yup.mixed()
              .nullable()
              .test("test-file-loaded", "File is required", (val) => {
                return !!(val as unknown as File)?.size
              }),
          })}
        >
          {({ isSubmitting, values: { organization, overwritePatients } }) => (
            <PatientImportForm
              isSubmitting={isSubmitting}
              organizationId={organizationId ? organizationId : organization?.id}
              overwritePatients={overwritePatients}
            />
          )}
        </FormContainer>
      )}
    </Slideover>
  )
}

const initialValues: Partial<UploadData> = {
  organization: undefined,
  practitioner: undefined,
  file: undefined,
  overwritePatients: false,
  invitePatients: false,
}

type Props = {
  visible: boolean
  onHide(): void
  isSmallViewport?: boolean
}

export { PatientImport }
