import { useQuery, keepPreviousData } from "@tanstack/react-query"
import { asReference, Reference, type Practitioner } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { PractitionerApi } from "commons"
import { useLoginContext } from "security"
const usePractitionersRefs = (filter: string = "") => {
  const { search } = useClient()
  const { logedInPractitioner } = useLoginContext()
  const queryKey = ["practitionerReference", filter]

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        ...(filter ? { term: filter } : {}),
        active: "true",
        _count: "30",
        _sort: ".name.0.family",
      })

      const bundle = await search("practitioners", filters)
      const practitioners = bundle.entry as PractitionerApi[]

      return { practitioners, total: bundle?.total ?? 0 }
    },
    enabled: !!logedInPractitioner.practitioner.id && !!filter,
    placeholderData: keepPreviousData,
  })

  const { practitionerRefs, practitioners } = useMemo(() => {
    if (!data?.practitioners) return { practitionerRefs: [], practitioners: {} }

    const practitionerRefs: Reference[] = []
    const practitioners: Record<string, Practitioner> = {}

    for (const { practitioner } of data.practitioners) {
      if (practitioner.id) {
        practitioners[practitioner.id] = practitioner
        practitionerRefs.push(asReference(practitioner))
      }
    }

    return { practitionerRefs, practitioners }
  }, [data?.practitioners])

  return { practitionerRefs, practitioners, isLoading, isFetching, total: data?.total }
}

export { usePractitionersRefs }
