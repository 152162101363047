import {
  faBuildingShield,
  faCancel,
  faCheck,
  faCheckCircle,
  faCopy,
  faDollarSign,
  faEdit,
  faExternalLink,
  faFileExport,
  faUpload,
} from "@fortawesome/pro-light-svg-icons"
import {
  faBuilding,
  faBuildingColumns,
  faEnvelope,
  faLocationDot,
  faPhone,
  faSitemap,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString, getAddress, getFirstEmail, getFirstPhone, Organization } from "fhir"
import { MenuItem } from "primereact/menuitem"
import { Tooltip } from "primereact/tooltip"

import { BadgeProps, StackedListItemProps } from "commons"
import { STRIPE_SYSTEM } from "data"
import { copyToClipboard, displayNotificationSuccess } from "utils"

import { OrganizationApi } from "../types"

const orgModelBuilder = (
  org: OrganizationApi,
  editOrganization: (org: Organization) => void,
  handleShowStatusDialog: (org: OrganizationApi) => void,
  setShowOnboardDialog: (org: OrganizationApi) => void,
  handleShowPaymentDialog: (org: OrganizationApi) => void,
  viewAdministration: (org: Organization) => void,
  handleExportPatients: (org: Organization) => void,
  handleImportPatient: (org: Organization) => void,
  isLoading: boolean,
): StackedListItemProps => {
  const { organization, status } = org

  const hasStripeSetup = organization.identifier?.some((i) => i.system === STRIPE_SYSTEM)
  const ehrURL = `${window.VITE_APP_EHR_APP_URL}/orgs/${org.organization?.id}/patients`

  const isOnboardDisabled = () => {
    switch (status?.statusCode) {
      case "active":
      case "in-progress":
      case "pending":
        return true
      case "expired":
      case "failed":
      case "unknown":
      case "not-found":
        return false
      default:
        return false
    }
  }

  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      {
        label: "Edit",
        icon: <FontAwesomeIcon icon={faEdit} size="sm" className="mr-2" />,
        command: () => editOrganization(org.organization),
      },
      {
        label: "Administration",
        icon: <FontAwesomeIcon icon={faBuildingShield} size="sm" className="mr-2" />,
        command: () => viewAdministration(organization),
      },
      {
        label: organization.active === false ? "Activate" : "Deactivate",
        icon: <FontAwesomeIcon icon={org.organization.active ? faCancel : faCheck} size="sm" className="mr-2" />,
        command: () => handleShowStatusDialog(org),
      },
      {
        label: "Onboard",
        icon: <FontAwesomeIcon icon={faCheckCircle} size="sm" className="mr-2" />,
        command: () => setShowOnboardDialog(org),
        disabled: isOnboardDisabled() || organization.active === false,
      },
      {
        label: "Invite Payment Setup",
        icon: <FontAwesomeIcon icon={faDollarSign} size="sm" className="mr-2" />,
        command: () => handleShowPaymentDialog(org),
        disabled: hasStripeSetup || organization?.address?.[0].use !== "billing",
      },
      {
        label: "See on EHR",
        icon: (
          <>
            {organization.active === false && (
              <Tooltip content="The organization needs to be active" target=".active" position="left" />
            )}
            <FontAwesomeIcon icon={faExternalLink} size="sm" className="mr-2" />
          </>
        ),
        url: ehrURL,
        target: "_blank",
        disabled: organization.active === false,
        className: "active",
      },
      {
        label: "Copy id",
        icon: <FontAwesomeIcon icon={faCopy} size="sm" className="mr-2" />,
        command: () =>
          copyToClipboard(org.organization.id as string, () => displayNotificationSuccess("Copied to clipboard")),
      },
      {
        label: "Export Patients",
        icon: <FontAwesomeIcon icon={faFileExport} size="sm" className="mr-2" />,
        command: () => handleExportPatients(org.organization),
      },
      {
        label: "Import Patient",
        icon: <FontAwesomeIcon icon={faUpload} size="sm" className="mr-2" />,
        command: () => handleImportPatient(org.organization),
      },
    ]

    return menuItems
  }
  const getBadgeProps = (): BadgeProps => ({
    text: organization.active === false ? "deactivated" : "active",
    colorStyle: organization.active === false ? "red" : "green",
  })

  return {
    leftData: [
      { lineItems: [{ name: "Name", value: organization.name, icon: faBuilding }] },
      {
        lineItems: [
          { name: "Address", value: getAddress(organization.address), icon: faLocationDot },
          { name: "Email", value: getFirstEmail(organization.telecom) ?? "No email provided", icon: faEnvelope },
          { name: "Phone", value: getFirstPhone(organization?.telecom), icon: faPhone },
          { name: "Type", value: codeableConceptAsString(organization?.type?.[0]), icon: faSitemap },
          ...(organization.partOf?.display
            ? [{ name: "Part of", value: organization.partOf?.display, icon: faBuildingColumns }]
            : []),
        ],
      },
    ],
    badge: getBadgeProps(),
    menu: getMenuItems(),
    isLoading,
  }
}

export { orgModelBuilder }
