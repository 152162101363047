import { createContext, useContext } from "react"
import { AnyObject } from "yup/lib/types"

/* The createContext function is used to create a context object that can be used to share state across different components in a React application without having to pass props down manually at every level. 
In this case, the context is initialized with a value of null!, which is a TypeScript non-null assertion operator. 
This operator is used to tell TypeScript that even though null is being assigned, it should not consider it as a potential null value for type-checking purposes. 
This is often done to bypass TypeScript's strict null checks during the initial creation of the context, with the expectation that a proper value will be provided later on. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FiltersContext = createContext<State<any>>(null!)

FiltersContext.displayName = "FiltersContext"

const useFiltersContext = <T extends AnyObject>() => {
  const context = useContext<State<T>>(FiltersContext)

  if (!Object.keys(context).length) throw new Error("useFiltersContext must be used withing FiltersContextProvider")

  return context
}

type Props<T extends AnyObject> = {
  initialFilters: T
  initialSearchText: string
  filtersKeysValueMap?: {
    [K in keyof T]?: (val: AnyObject | Date | string | boolean | number | undefined) => string | undefined
  }
  filtersKeysNameMap?: { [K in keyof T]?: string }
  setUrlFilters?: boolean
  isLoadingData?: boolean
}

export type State<T extends AnyObject> = {
  filters: T
  searchText: string | undefined
  hasActiveFilters: boolean
  showOverlay: boolean
  onSearch(searchText: string | undefined): void
  onFilter(filters: T): void
  onClearFilters(): void
  onClearAll(): void
  onShowOverlay(show: boolean): void
} & Props<T>

export { FiltersContext, useFiltersContext, type Props as InitialProps }
